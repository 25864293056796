import axios from "axios";
import { UserLastAccessDay } from '@/types/user_last_access_day'
import { UserAccess } from '@/types/user_accesses'

export class ProjectUserAccessRepository {
  private url: string;
  private static url(project_id: number) {
    return process.env.VUE_APP_DOMAIN + 'v1/projects/' + project_id + '/project_user_accesses/'
  }

  constructor(project_id: number) {
    this.url = ProjectUserAccessRepository.url(project_id);
  }

  async getList(): Promise<UserLastAccessDay[]> {
    return axios.get(this.url).then((response) => {
      return response.data.users;
    });
  }
  async getUserAccesses(user_id: number): Promise<UserAccess[]> {
    return axios.get(this.url + "user_accesses", { params: { user_id: user_id } }).then((response) => {
      return response.data.user_accesses;
    });
  }

}

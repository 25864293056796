import axios from "axios";
import { ElMessage } from "element-plus"
import { ProjectUserAccessRepository } from '@/repositories/ProjectUserAccessRepository'
import { UserLastAccessDay } from '@/types/user_last_access_day'
import { UserAccess } from '@/types/user_accesses'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
export const ProjectUserAccessLogic = () => {
  const route = useRoute();
  const projectUserAccessErrors = ref("");
  const projectUserAccessLoading = ref(false)
  const users = ref<UserLastAccessDay[]>([])
  const user_accesses = ref<UserAccess[]>([])

  const getUserLastAccesses = async () => {
    const projectId = Number(route.params.project_id);
    projectUserAccessLoading.value = true

    const repository: ProjectUserAccessRepository = new ProjectUserAccessRepository(projectId);
    try {
      const result = await repository.getList();
      users.value = result
      projectUserAccessLoading.value = false;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        projectUserAccessErrors.value = `取得に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
      }
    }
  }
  const getUserAccesses = async (user_id: number) => {
    const projectId = Number(route.params.project_id);
    projectUserAccessLoading.value = true

    user_accesses.value = []

    const repository: ProjectUserAccessRepository = new ProjectUserAccessRepository(projectId);
    try {
      const result = await repository.getUserAccesses(user_id)
      user_accesses.value = result
      projectUserAccessLoading.value = false;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        projectUserAccessErrors.value = `取得に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
      }
    }
  }
  watch(projectUserAccessErrors, () => {
    if (projectUserAccessErrors.value === "") {
      return;
    }
    ElMessage({
      showClose: true,
      message: projectUserAccessErrors.value,
      type: 'error'
    });
    projectUserAccessErrors.value = "";
  });

  return {
    users,
    user_accesses,
    getUserLastAccesses,
    getUserAccesses,
    projectUserAccessLoading
  };
}
